<template>
  <div>
    <div style="margin-bottom: 8px;">
      <el-button @click="liveViewDialogOpen"> <i class="fas fa-video"></i>&nbsp;카메라 제어 </el-button>
    </div>

    <fclt-plugin-status
      ref="fcltPluginStatus"
      :title="'CCTV 상태정보'"
      :statHederInfo="statHederInfo"
      :fcltId="this.fcltData.fcltId"
    ></fclt-plugin-status>

    <div class="dk-card">
      <table class="el-table el-table--mini">
        <tr>
          <th>시설물 명칭</th>
          <td>{{ fcltData.fcltName }}</td>
        </tr>
        <tr>
          <th>시설물 유형</th>
          <td>{{ fcltData.fcltTypeName }}</td>
        </tr>
        <tr>
          <th>도로명 주소</th>
          <td>{{ fcltData.setRoadAddr }}</td>
        </tr>
        <tr>
          <th>지번 주소</th>
          <td>{{ fcltData.setNumberAddr }}</td>
        </tr>
        <tr>
          <th>경도</th>
          <td>{{ fcltData.gisLng }}</td>
        </tr>
        <tr>
          <th>위도</th>
          <td>{{ fcltData.gisLat }}</td>
        </tr>
        <tr v-for="(ftData, index) in checkFcltData" :key="index">
          <th>{{ ftData.label }}</th>
          <td>{{ getFcltDataValue(ftData) }}</td>
        </tr>
      </table>
    </div>
    <dialog-cctv-viewer ref="dialogCctvViewer" fcltTypeId="FCLTTYPE10002"></dialog-cctv-viewer>
  </div>
</template>
<script>
import fcltPluginStatus from '@/traffic/widget/fcltPluginStatus.vue';
import dialogCctvViewer from './dialogCctvViewer.vue';

export default {
  components: {
    'fclt-plugin-status': fcltPluginStatus,
    'dialog-cctv-viewer': dialogCctvViewer,
  },
  name: 'dk-road-cctv',
  label: 'CCTV',
  computed: {
    checkFcltData() {
      if (this.fcltData.fcltTypeData) return this.fcltData.fcltTypeData;
      else [];
    },
  },
  data() {
    return {
      statHederInfo: [
        {
          key: 'powerStatus',
          name: '전원',
          textInfo: {
            ERR: '에러',
            OFF: '꺼짐',
            ON: '정상',
          },
        },
        {
          key: 'doorStatus',
          name: '문 열림',
          textInfo: {
            ERR: '에러',
            OFF: '꺼짐',
            ON: '정상',
          },
        },
        {
          key: 'fanStatus',
          name: '팬 가동',
          textInfo: {
            ERR: '에러',
            OFF: '꺼짐',
            ON: '정상',
          },
        },
        {
          key: 'heaterStatus',
          name: '히터 가동',
          textInfo: {
            ERR: '에러',
            OFF: '꺼짐',
            ON: '정상',
          },
        },
      ],
    };
  },
  props: {
    fcltData: {
      required: true,
      default: {},
    },
  },
  created() {},
  destroyed() {},
  methods: {
    liveViewDialogOpen() {
      this.$refs.dialogCctvViewer.showDialog(this.fcltData);
    },
    getFcltDataValue(fcltData) {
      let val = fcltData.value;
      if (fcltData.formType == 'combobox') {
        if (fcltData.options) {
          let tempOption = fcltData.options.find((opt) => {
            return opt.value == fcltData.value;
          });
          if (tempOption) {
            val = tempOption.label;
          }
        }
      }
      return val;
    },
  },
};
</script>
